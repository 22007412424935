import BackendService from './BackendService';
const singleName = 'usuario';
//const pluralName = singleName+'s';




export default class UsuarioService extends BackendService {

    constructor() {
        super();
    }
    /*registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                // eslint-disable-next-line no-redeclare
                var user = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }*/

    /**
     * Login user with given details
     */
    loginUser(email, password){
        return this.httpCall('login/admin',{
                method: 'POST',
                data: {email:email, password:password},
            });/*.then((user) => {
                // eslint-disable-next-line no-redeclare
                console.log('user', user);
                //var user = firebase.auth().currentUser;
                resolve(user);
            }).catch((error) => {
                console.log('useerrorr', error);
                reject(error);
            });*/

        
    }

    /**
     * forget Password user with given details
     */
    /*forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    /*logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }*/

    create(data) {


        //console.log('esto se envio::',  data);
        //return data;
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
        });

    }

    update(data) {
       //console.log('contrato, con: ', contract)
        return this.httpCall(singleName+'/'+data.id,{
            method: 'PUT',
            data: data,
        });
    }
    toggleStatus(id){
        return this.httpCall(singleName+'/'+id+'/toggle',{
            method: 'PUT',
        });
    }

    delete(id) {
        return this.httpCall(singleName+'/'+id,{
            method: 'delete'
        });
    }

    getById(id) {

        return this.httpCall(singleName+'/'+id);

        //return this.httpCall('property' + property_id + '/users');
    }

    getList(params) {
        //console.log('get rooms');

        //return testItems;
        return this.httpCall(singleName,{
            params: params,
        });
    }
    

}
