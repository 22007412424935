import store from '@/state/store'
export default [{
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  }, {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    //component: () => import('../views/pages/icons/materialdesign')
    //component: () => import('../views/pages/icons/unicons')
    component: () => import('../views/project/pedido/dashboard')
  },
  {
    path: '/arreglos',
    name: 'Arreglo',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/arreglo/list')
  },

  {
    path: '/arreglos/agregar',
    name: 'ArregloAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/arreglo/form')
  },
  
  {
    path: '/arreglos/editar/:id',
    name: 'ArregloEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/arreglo/form')
  },
  {
    path: '/categorias',
    name: 'Categoria',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/categoria/list')
  },

  {
    path: '/categorias/agregar',
    name: 'CategoriaAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/categoria/form')
  },
  
  {
    path: '/categorias/editar/:id',
    name: 'CategoriaEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/categoria/form')
  },
  
  {
    path: '/tipos',
    name: 'Tipo',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/tipo/list')
  },

  {
    path: '/tipos/agregar',
    name: 'TipoAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/tipo/form')
  },
  
  {
    path: '/tipos/editar/:id',
    name: 'TipoEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/tipo/form')
  },
  {
    path: '/pedidos',
    name: 'Pedido',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/pedido/list')
  },
  
  
  {
    path: '/sliders',
    name: 'Slider',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/slider/list')
  },

  {
    path: '/sliders/agregar',
    name: 'SliderAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/slider/form')
  },
  
  {
    path: '/sliders/editar/:id',
    name: 'SliderEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/slider/form')
  },
  
  
  
  {
    path: '/seo-general',
    name: 'SeoGeneral',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/seo/list')
  },

  {
    path: '/seo-general/agregar',
    name: 'SeoGeneralAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/seo/form')
  },
  
  {
    path: '/seo-general/editar/:id',
    name: 'SeoGeneralEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/seo/form')
  },  
  
  {
    path: '/zonas',
    name: 'Zona',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/zona/list')
  },

  {
    path: '/zonas/agregar',
    name: 'ZonaAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/zona/form')
  },
  
  {
    path: '/zonas/editar/:id',
    name: 'ZonaEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/zona/form')
  },
  
  {
    path: '/topten',
    name: 'Topten',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/topten/list')
  },

  {
    path: '/topten/agregar',
    name: 'ToptenAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/topten/form')
  },
  
  {
    path: '/topten/editar/:id',
    name: 'ToptenEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/topten/form')
  },


  {
    path: '/pedidos',
    name: 'Pedido',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/pedido/list')
  },
  
  {
    path: '/pedidos/agregar',
    name: 'PedidoAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/pedido/form')
  },
  {
    path: '/pedidos/editar/:id',
    name: 'PedidoEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/pedido/form')
  },
  {
    path: '/pedidos/ver/:id',
    name: 'PedidoShow',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/pedido/detail')
  },
  {
    path: '/clientes',
    name: 'Cliente',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/cliente/list')
  }, 
  {
    path: '/clientes/agregar-example',
    name: 'ClienteExample',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/icons/materialdesign')
  },
  {
    path: '/clientes/agregar',
    name: 'ClienteAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/cliente/form')
  }, {
    path: '/clientes/editar/:id',
    name: 'ClienteEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/cliente/form')
  },
 
  {
    path: '/usuarios',
    name: 'Usuarios',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/usuario/list')
  }, 
  {
    path: '/usuarios/agregar',
    name: 'UsuarioAdd',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/usuario/form')
  }, 
  {
    path: '/usuarios/editar/:id',
    name: 'UsuarioEdit',
    meta: {
      authRequired: true
    },
    component: () => import('../views/project/usuario/form')
  }



 

]